<template>
  <div class="multi-country-map-d3-container">
    <svg ref="multi-country-map-d3"></svg>
    <div ref="multi-country-map-d3-tooltip" class="tooltip-map"></div>
    <div ref="legends" class="legends"></div>
  </div>
</template>

<script>
import numeral from 'numeral';
import * as d3 from 'd3';
import usStatesData from './us-states.json';
import usDmasData from './us-dmas-nielsen.json';
import auStatesData from './au-states.json';
import worldMapData from './world-countries.json';
import { formatNumberAsFullNumber } from '~/util/numeral';
import { convertToNumber } from '~/util/utility-functions';

const width = 1000;
const height = 650;
const radius = 4;
const strokeWidth = 1;
const maxTicks = 7;

const colors = ['#b7bec5', '#a5aeb6', '#939ea8', '#818d99', '#66cdd4', '#33bcc5', '#00abb7'];

export default {
  name: 'MultiCountryMapChart',
  props: {
    mapCountry: {
      type: String,
      required: false,
      default: () => 'US',
    },
    // possible values are world,state,dma,address
    mapType: {
      type: String,
      required: false,
      default: () => 'state',
    },
    mapData: {
      type: Array,
      required: false,
      default: () => [],
    },
    valueKey: {
      type: String,
      required: false,
      default: () => 'Impressions',
    },
    valueType: {
      type: String,
      required: true,
    },
    legendValueFormat: {
      type: String,
      required: true,
    },
  },
  data() {
    const locationTypeMap = {
      world: 'Country',
      dma: 'DMA',
      address: 'Address',
      state: 'State',
    };

    const locationType = locationTypeMap[this.mapType] || 'State';

    return {
      map: null,
      tooltip: null,
      path: null,
      locationType,
      ticks: [],
    };
  },
  watch: {
    mapData(n) {
      const vm = this;

      vm.init(n);
    },
  },
  mounted() {
    const vm = this;

    vm.init();
  },
  beforeDestroy() {
    const vm = this;

    d3.select(vm.$refs['multi-country-map-d3']).remove();
    vm.map = null;
    vm.tooltip = null;
  },
  methods: {
    init() {
      const vm = this;

      vm.map = d3
        .select(vm.$refs['multi-country-map-d3'])
        .attr('width', width)
        .attr('height', height)
        .attr('align-self', 'center');

      const g = vm.map.append('g');

      vm.tooltip = d3
        .select(vm.$refs['multi-country-map-d3-tooltip'])
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('width', 'max-content')
        .style('background-color', '#fff')
        .style('color', 'black')
        .style('border', 'solid')
        .style('border-width', '0px')
        .style('border-radius', '5px')
        .style('padding', '10px')
        .style('box-shadow', '2px 2px 20px')
        .style('opacity', '0.8')
        .attr('id', 'tooltip');

      const zoom = d3
        .zoom()
        .scaleExtent([8])
        .on('zoom', function() {
          if (d3?.event?.transform) {
            g.selectAll('path').attr('transform', d3?.event?.transform);
          }
        });
      vm.map.call(zoom);

      // Generate highest values all possible impressions for generating chart legend heatmap.
      const geoTally = vm.mapData.map((location) => location.tally);

      // Find first legend value
      const max = d3.max(geoTally);
      const min = d3.min(geoTally);
      const factor = max / maxTicks;
      let firstLegendValue = min - factor;
      if (firstLegendValue < 0) {
        firstLegendValue = 0;
      }
      vm.ticks = [];
      for (let i = 0; i < maxTicks; i++) {
        vm.ticks.push(vm.formattedTickValue(firstLegendValue + factor * i, vm.legendValueFormat));
      }
      vm.ticks.push(
        vm.formattedTickValue(firstLegendValue + factor * maxTicks, vm.legendValueFormat)
      );

      const legW = width / 5;
      const legH = 20;
      const legendColors = colors;

      const legend = vm.map
        .append('g')
        .attr('transform', 'translate(790, 600)')
        .attr('id', 'legend');

      const xScale = d3
        .scaleLinear()
        .domain([0, 70])
        .range([0, legW]);

      const xAxis = d3
        .axisBottom()
        .scale(xScale)
        .ticks(maxTicks)
        .tickSize(30)
        .tickFormat((d, i) => vm.ticks[i]);

      legend
        .selectAll('rect')
        .data(legendColors)
        .enter()
        .append('rect')
        .attr('width', legW / legendColors.length)
        .attr('height', legH)
        .attr('x', (d, i) => i * (legW / legendColors.length))
        .style('fill', function(d, i) {
          return legendColors[i];
        });

      legend.append('g').call(xAxis);

      const projection = vm.getD3Projection();

      vm.path = d3.geoPath().projection(projection);

      if (vm.mapType === 'address') {
        vm.plotAddressMap(vm.mapData, projection);
      } else {
        vm.plotMap(vm.mapData);
      }
    },
    formattedTickValue(value, format) {
      const isPercentageFormat = format && format.endsWith('%');
      const valueClone = isPercentageFormat ? value / 100 : value;
      return numeral(valueClone).format(format);
    },
    getD3Projection() {
      if (this.mapType === 'world') {
        return d3
          .geoMercator()
          .center([-20, 60])
          .scale([150]);
      }

      if (this.mapCountry === 'AU') {
        return d3
          .geoMercator()
          .center([131, -25])
          .scale([850]);
      }
      return d3
        .geoAlbersUsa()
        .translate([550, 300]) // translate to center of screen
        .scale([1200]);
    },
    getGeoLocations(country, mapType) {
      if (mapType === 'world') {
        return JSON.parse(JSON.stringify(worldMapData));
      }

      if (country === 'AU') {
        return JSON.parse(JSON.stringify(auStatesData));
      }

      return JSON.parse(JSON.stringify(mapType === 'dma' ? usDmasData : usStatesData));
    },
    plotMap(mapData) {
      const vm = this;

      // Convert array to key-value pair
      const geoTallyLookup = {};
      for (let i = 0; i < mapData.length; i++) {
        const geo = mapData[i];
        geoTallyLookup[geo.location] = geo.tally;
      }

      let { mapType } = vm;
      if (vm.mapCountry === 'AU' && mapType === 'dma') {
        mapType = 'state';
      }

      // Reload GeoJSON data
      const geoLocations = vm.getGeoLocations(vm.mapCountry, mapType);

      let propertyName;
      if (vm.mapType === 'world') {
        propertyName = 'ADMIN';
      } else if (vm.mapCountry === 'AU') {
        propertyName = 'STATE_NAME';
      } else {
        propertyName = mapType === 'dma' ? 'dma' : 'name';
      }

      // Lookup location in key-value pair, if found update the value
      for (let j = 0; j < geoLocations.features.length; j++) {
        const feature = geoLocations.features[j];
        const location = feature.properties[propertyName];
        const tally = geoTallyLookup[location];
        if (tally) {
          feature.properties.location = mapType === 'dma' ? feature.properties.dma1 : location;
          feature.properties.tally = tally;
        }
      }

      vm.map
        .select('g')
        .selectAll('path')
        .data(geoLocations.features)
        .enter()
        .append('path')
        .attr('d', vm.path)
        .style('fill', function(d) {
          const { tally } = d.properties;

          if (tally) {
            let colorIndex;
            const formattedTally = convertToNumber(formatNumberAsFullNumber(tally, vm.valueType));
            if (formattedTally <= convertToNumber(vm.ticks[0])) {
              colorIndex = 0;
            } else if (formattedTally >= convertToNumber(vm.ticks[vm.ticks.length - 1])) {
              colorIndex = colors.length - 1;
            } else {
              for (let i = 0; i < vm.ticks.length - 1; i++) {
                const inRange =
                  formattedTally >= convertToNumber(vm.ticks[i]) &&
                  formattedTally < convertToNumber(vm.ticks[i + 1]);
                if (inRange) {
                  colorIndex = i;
                  break;
                }
              }
            }

            return colors[colorIndex];
          }
          return 'rgb(183,190,197)';
        })
        .on('mouseover', function() {
          d3.select(this).attr('stroke', 'black');
        })
        .on('mouseout', function() {
          d3.select(this).attr('stroke', 'none');
        });

      vm.$refs['multi-country-map-d3'].addEventListener('mouseover', function mouseoverEv(e) {
        if (!e.target.__data__) {
          return;
        }
        const d = e.target.__data__;
        if (d && d.properties && d.properties.tally > 0) {
          vm.tooltip.style('visibility', 'visible');
          vm.tooltip
            .html(
              `<center>${d.properties.location}: ${formatNumberAsFullNumber(
                d.properties.tally,
                vm.valueType
              )}</center>`
            )
            .style('top', `${e.offsetY - 40}px`)
            .style('left', `${e.offsetX + 10}px`);
        } else {
          vm.tooltip.style('class', 'hidetooltip');
          d3.selectAll('.tooltip-map').style('visibility', 'hidden');
        }
      });

      vm.$refs['multi-country-map-d3'].addEventListener('mouseout', function mouseoutEv() {
        d3.selectAll('.tooltip-map').style('visibility', 'hidden');
      });
    },
    plotAddressMap(mData, projection) {
      const vm = this;
      const mapData = mData.filter((d) => d.lat && d.lng);

      // Reload GeoJSON data
      const geoLocations = vm.getGeoLocations(vm.mapCountry, vm.mapType);

      vm.map
        .select('g')
        .selectAll('path')
        .data(geoLocations.features)
        .enter()
        .append('path')
        .attr('d', vm.path)
        .attr('stroke', '#b7bec5')
        .style('fill', 'rgb(240,240,240)');

      vm.map
        .select('g')
        .selectAll('circle')
        .data(mapData)
        .enter()
        .append('circle')
        .attr('cx', function(d) {
          return projection([d.lng, d.lat])[0];
        })
        .attr('cy', function(d) {
          return projection([d.lng, d.lat])[1];
        })
        .attr('r', radius)
        .style('fill', function(d) {
          const { tally } = d;

          if (tally) {
            let colorIndex;
            const formattedTally = convertToNumber(formatNumberAsFullNumber(tally, vm.valueType));
            if (formattedTally <= convertToNumber(vm.ticks[0])) {
              colorIndex = 0;
            } else if (formattedTally >= convertToNumber(vm.ticks[vm.ticks.length - 1])) {
              colorIndex = colors.length - 1;
            } else {
              for (let i = 0; i < vm.ticks.length - 1; i++) {
                const inRange =
                  formattedTally >= convertToNumber(vm.ticks[i]) &&
                  formattedTally < convertToNumber(vm.ticks[i + 1]);
                if (inRange) {
                  colorIndex = i;
                  break;
                }
              }
            }

            return colors[colorIndex];
          }
          return 'rgb(27,197,184)';
        })
        .style('stroke-width', `${strokeWidth}px`)
        .style('stroke', 'rgb(0,164,138)')
        .style('opacity', 0.85)
        .on('mouseover', function(d) {
          const el = d3.select(this);
          const pos = el.node().getBoundingClientRect();
          const px = pos.x - 700;
          const py = pos.y - 50;

          vm.tooltip.style('visibility', 'visible');
          vm.tooltip
            .html(
              `${vm.locationType}: ${d.location}<br/><br/>
                ${vm.valueKey}: ${formatNumberAsFullNumber(d.tally, vm.valueType)}`
            )
            .style('left', `${px}px`)
            .style('top', `${py}px`);
        })
        .on('mouseout', function() {
          vm.tooltip.style('class', 'hidetooltip');
          d3.selectAll('.tooltip-map').style('visibility', 'hidden');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-country-map-d3-container {
  transform-origin: top left;
  transform: scale(0.63);
  margin-left: -20px;
}
@media (max-width: 1399px) {
  .multi-country-map-d3-container {
    transform: scale(0.6) !important;
  }
}
@media (max-width: 1349px) {
  .multi-country-map-d3-container {
    transform: scale(0.57) !important;
  }
}
@media (max-width: 1299px) {
  .multi-country-map-d3-container {
    transform: scale(0.55) !important;
    margin-left: -30px;
  }
}
@media (max-width: 1259px) {
  .multi-country-map-d3-container {
    transform: scale(0.52) !important;
  }
}
@media (max-width: 1239px) {
  .multi-country-map-d3-container {
    transform: scale(0.5) !important;
  }
}
@media (max-width: 1199px) {
  .multi-country-map-d3-container {
    transform: scale(0.45) !important;
  }
}
@media (max-width: 1100px) {
  .multi-country-map-d3-container {
    transform: scale(0.37) !important;
  }
}
@media (max-width: 1000px) {
  .multi-country-map-d3-container {
    transform: scale(0.3) !important;
  }
}
@media (max-width: 900px) {
  .multi-country-map-d3-container {
    transform: scale(0.63) !important;
  }
}
.legends {
  padding: 0.5rem;
  text-align: center;
}
.tooltip-map {
  //font-size: 1.5em;
  background-color: #1a1b1d !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  z-index: 2;
  text-align: left;
  padding: 3px 6px !important;
  color: #999 !important;
  opacity: 0.96 !important;
  font-family: 'Manrope', sans-serif !important;
  line-height: 1.4285em !important;
  transform: scale(1.2) !important;
  -webkit-transition: opacity 0.6s ease-in-out;
  -moz-transition: opacity 0.6s ease-in-out;
  -ms-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
}
</style>
